
import Link from 'next/link'
import { Button } from '@saatva-bits/pattern-library.components.button'
import InfoTooltip from '@/components/InfoTooltip'

const rightBedSetup = {
    title: 'Find the right bed setup for you',
    description: <>
        Comfort starts from the ground up. The right bed base not only upgrades your sleep comfort, it can increase the lifespan of your mattress.<br/>
        Let's break down all of the options:
    </>,
    leftImage: true,
    lightMode: true,
    content: [
        {
            tab: 'Platform Bed Frame',
            content () {
                return (
                    <>
                        <h4>This setup is great for:</h4>
                        <ul>
                            <li>A sleek, low-profile look</li>
                            <li>Those who prefer not to use a foundation or box spring</li>
                        </ul>
                        <h4>Compatible with:</h4>
                        <p>
                            Most mattresses
                            <InfoTooltip>
                                <p>NOTE: Must add a solid surface over the slats such as plywood, masonite board, or bunkie board, when used with the dual-sided <strong>Zenhaven Natural Latex</strong> Mattress.</p>
                            </InfoTooltip>
                        </p>
                        <div>
                            <Button kind="primary" href='/furniture?styles=platform+bed+frames'>
                                Shop platform bed frames
                            </Button>
                        </div>
                    </>
                )
            },
            image: {
                folder: 'products/siena-leather/front/espresso',
                file: 'siena-leather-front-espresso-16-9.jpg'
            }
        },
        {
            tab: 'Standard Bed Frame + Foundation',
            content () {
                return (
                    <>
                        <h4>Foundation vs. box spring: What’s the difference?</h4>
                        <p>
                            A mattress foundation is a <strong>new & improved</strong> version of the old-fashioned box spring.
                            Foundations use more stable wood support slats, while box springs use bouncier metal coils for support.
                        </p>
                        <h4>This setup is great for:</h4>
                        <ul>
                            <li>Making your bed the focal point of your bedroom</li>
                            <li>Giving added height to your mattress</li>
                            <li>Mattresses that require solid surface support (no slats), like our Zenhaven Latex Mattress & Saatva HD</li>
                        </ul>
                        <h4>Compatible with:</h4>
                        <p>
                            <Link href='/mattresses' className='t-link'>All mattresses</Link> (Our Saatva HD requires the HD foundation for optimal support)
                        </p>
                        <div>
                            <Button kind="primary" href='/furniture/foundation'>
                                Shop mattress foundation
                            </Button> 
                            <Button kind="primary" href='/furniture?styles=standard+bed+frames'>
                                Shop standard bed frames
                            </Button>
                        </div>
                    </>
                )
            },
            image: {
                folder: 'products/amalfi/front/linen',
                file: 'amalfi-front-linen-16-9.jpg'
            }
        },
        {
            tab: 'Foundation',
            content () {
                return (
                    <>
                        <h4>Foundation vs. box spring: What’s the difference?</h4>
                        <p>
                            A mattress foundation is a <strong>new & improved</strong> version of the old-fashioned box spring.
                            Foundations use more stable wood support slats, while box springs use bouncier metal coils for support.
                        </p>
                        <h4>This setup is great for:</h4>
                        <ul>
                            <li>Minimalists who want the simplest and least expensive bed setup</li>
                            <li>Those who move often and don’t want to disassemble a large bed frame each time</li>
                        </ul>
                        <h4>Compatible with:</h4>
                        <p>
                            <Link href='/mattresses' className='t-link'>All mattresses</Link> (Our Saatva HD requires the HD foundation for optimal support)
                        </p>
                        <p>
                            <small><em>Optional Universal Frame sold separately</em></small>
                        </p>
                        <div>
                            <Button kind="primary" href='/furniture/foundation'>
                                Shop mattress foundation
                            </Button>
                        </div>
                    </>
                )
            },
            image: {
                folder: 'products/foundation/angle/4.75',
                file: 'foundation-angle-4.75-16-9.jpg'
            }
        },
        {
            tab: 'Adjustable Base',
            content () {
                return (
                    <>
                        <h4>This setup is great for:</h4>
                        <ul>
                            <li>Helping to relieve back & joint pain</li>
                            <li>Those who enjoy reading, watching TV, or working from bed</li>
                            <li>Having the freedom to raise the head and the feet of the mattress independently*</li>
                        </ul>
                        <p>
                            <small>* Feature only available on the Saatva Adjustable Base Plus Split King or Split Cal King sizes</small>
                        </p>
                        <h4>Compatible with:</h4>
                        <ul>
                            <li>All Saatva mattresses except Saatva Classic 14.5” height & Saatva HD</li>
                            <li>All Saatva bed frames except Sydney Platform Bed Frame and all storage bed frames</li>
                        </ul>
                        <div>
                            <Button kind="primary" href='/furniture?types=adjustable+bases'>
                                Shop adjustable bases
                            </Button>
                        </div>
                    </>
                )
            },
            image: {
                folder: 'products/adjustable-base-plus/silo/split-king',
                file: 'adjustable-base-plus-silo-split-king-16-9.jpg'
            }
        }
    ]
}

export default rightBedSetup
