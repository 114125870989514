const rugs = {
    title: 'Rug size guide',
    description: 'Transform your room into a cohesive space with the perfect rug to fit your layout & bed size.',
    content: [
        {
            tab: 'Queen Bed',
            content () {
                return (
                    <>
                        <h4>8’ x 10’ rug</h4>
                        <ul>
                            <li>Recommended for a queen bed & smaller spaces</li>
                            <li>Placed under the bed, it leaves 3’ of rug space on either side with room for night stands</li>
                        </ul>
                        <h4>9’ x 12’ rug</h4>
                        <ul>
                            <li>Recommended for larger spaces & accommodating larger bedroom furniture</li>
                            <li>Placed under the bed, it leaves 4’ of rug space on either side with room for night stands</li>
                        </ul>
                    </>
                )
            },
            image: {
                folder: 'generic/rug-size-guide/queen',
                file: 'rug-size-guide-queen-16-9.png'
            }
        },
        {
            tab: 'King Bed',
            content () {
                return (
                    <>
                        <h4>8’ x 10’ rug</h4>
                        <ul>
                            <li>Placed under the bed, it leaves 2’4” of rug space on either side with room for night stands</li>
                        </ul>
                        <h4>9’ x 12’ rug</h4>
                        <ul>
                            <li>Recommended for a king bed & larger spaces</li>
                            <li>Placed under the bed, it leaves 3’4” of rug space on either side with room for night stands</li>
                        </ul>
                        <h4>10’ x 14’ rug</h4>
                        <ul>
                            <li>Recommended for larger rooms & accommodating larger bedroom furniture</li>
                            <li>Placed under the bed, it leaves 4’4” of rug space on either side with room for night stands</li>
                        </ul>
                    </>
                )
            },
            image: {
                folder: 'generic/rug-size-guide/king',
                file: 'rug-size-guide-king-16-9.png'
            }
        },
        {
            tab: 'Cal King Bed',
            content () {
                return (
                    <>
                        <h4>9’ x 12’ rug</h4>
                        <ul>
                            <li>Placed under the bed, it leaves 3’ of rug space on either side with room for night stands</li>
                        </ul>
                        <h4>10’ x 14’ rug</h4>
                        <ul>
                            <li>Recommended for a Cal king bed & larger spaces</li>
                            <li>Placed under the bed, it leaves 4’ of rug space on either side with room for night stands</li>
                        </ul>
                    </>
                )
            },
            image: {
                folder: 'generic/rug-size-guide/cal-king',
                file: 'rug-size-guide-cal-king-16-9.png'
            }
        },
        {
            tab: 'Twin Bed',
            content () {
                return (
                    <>
                        <h4>8’ x 10’ rug</h4>
                        <ul>
                            <li>Recommended for a single twin or two twin beds & smaller spaces</li>
                        </ul>
                        <h4>9’ x 12’ rug</h4>
                        <ul>
                            <li>Placed under two twin beds side by side, it anchors the room and leaves enough space for a small side table in between</li>
                        </ul>
                    </>
                )
            },
            image: {
                folder: 'generic/rug-size-guide/twin',
                file: 'rug-size-guide-twin-16-9.png'
            }
        }
    ]
}

export default rugs
